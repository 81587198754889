import React, { useState, useEffect } from 'react'
import requestToServer from '../utils/requestToServer'
import { useHistory, Link } from 'react-router-dom'
import {
  Row,
  Col,
  Tab,
  Tabs,
  Table,
  Alert,
  OverlayTrigger,
  Tooltip,
  Button,
} from 'react-bootstrap'
import Sticky from 'react-stickynode'

import { css } from '@emotion/core'
import ScaleLoader from 'react-spinners/ScaleLoader'

//import { InfiniteScroll } from 'react-simple-infinite-scroll'
import InfiniteScroll from 'react-infinite-scroll-component'
//import MapContainer from './gmap/MapContainer'
import OrderDirectionIcon from './OrderDirectionIcon'
import DetailModal from './DetailModal'
import useBreakpoint from '../hooks/useBreakpoint'
import { appConfig } from '../config/'
const hostUrl = appConfig.debug ? appConfig.hostUrl : appConfig.hostRealUrl

let Scroll = require('react-scroll')
let Element = Scroll.Element
let scroller = Scroll.scroller

const override = css`
  display: block;
`

const queries = {
  xs: '(max-width: 576px)',
  md: '(max-width: 768px)',
  lg: '(max-width: 992px)',
}

export default function ListDataTableReal(props) {
  const [dataAllList, setDataAllList] = useState([])
  const [dataOneYearList, setDataOneYearList] = useState([])
  const [cursor, setCursor] = useState(0)
  const [total, setTotal] = useState(0)

  const [loading, setLoading] = useState(false)
  const [initLoading, setInitLoading] = useState(true)

  const [show, setShow] = useState(false)
  const [detailData, setDetailData] = useState(null)
  // orderby = transaction_date, total_price,
  // unit_price, address, building_type, age,
  // total_area, total_transfer_area
  // default transaction_date
  const [orderby, setOrderby] = useState('transaction_date')

  // orderdirection = desc, asc (default desc)
  const [orderdirection, setOrderdirection] = useState('desc')

  let history = useHistory()

  let matchPoints = useBreakpoint(queries)
  //console.log(matchPoints)
  if (!matchPoints) matchPoints = { xs: false }

  const {
    area,
    townshipOrStation,
    countryOrLine,
    searchType,
    //loading,
    //setLoading,
    btype,
    keytypeGlobal,
    minAll,
    maxAll,
    maxOneYear,
    minOneYear,
    countOneYear,
    countAll,
    street,
    rangeQuery, // range query from to
  } = props
  // did mount to request data from server

  // fixed total set too slowly bug
  useEffect(() => {
    const year = keytypeGlobal === 'all' ? 0 : 1
    const totalArray = year ? countOneYear : countAll
    const totalNow = getTotalNow(totalArray, btype)
    //console.log('totalNow', totalNow)
    setTotal(totalNow)
  }, [countAll, countOneYear, keytypeGlobal, btype, rangeQuery])

  const getTotalNow = (totalArray, btype) => {
    const count = []
    //const summary = { ...data.summary }

    for (let i = 0; i < 12; i++) {
      let tag = i

      // we donot need 0 (default)
      if (i === 0) tag = 'all'

      //console.log(summary['count_type_' + i])
      count.push(totalArray['count_type_' + tag])
    }

    return count[+btype]
  }

  const loadMore = () => {
    // open loading spinner
    //setInitLoading(true)

    let region = '信義區'
    let postcode = '110'

    if (townshipOrStation != null) {
      region = townshipOrStation.name
      postcode = townshipOrStation.postcode
    }
    const type = searchType ? searchType : 1
    const coordinate = townshipOrStation
      ? townshipOrStation.lat + ',' + townshipOrStation.lng
      : ''
    const path = type === 1 || type === 9 ? 'list' : 'clist'

    // cursor set to 0
    const year = keytypeGlobal === 'all' ? 0 : 1

    // do y-m range query
    const startDate = `${rangeQuery.from.year}-${
      rangeQuery.from.month < 10
        ? '0' + rangeQuery.from.month
        : rangeQuery.from.month
    }-01`

    const endDate = `${rangeQuery.to.year}-${
      rangeQuery.to.month < 10 ? '0' + rangeQuery.to.month : rangeQuery.to.month
    }-01`

    const query = `region=${region}&postcode=${postcode}&year=${year}&type=${type}&street=${street}&orderby=${orderby}&orderdirection=${orderdirection}&start=${cursor}&btype=${btype}&coordinate=${coordinate}&startDate=${startDate}&endDate=${endDate}`

    const url = `${hostUrl}/realestates/${path}/?${query}`
    const method = 'GET'
    const body = ''
    const verify = () => true
    const successCallback = (data) => {
      //setDataAllList(data.rows)
      //setLoading(false)
      if (data.status) {
        setDataAllList([...dataAllList, ...data.rows])
        // every rows is increment 50
        console.log(cursor)
        setCursor(cursor + 50)
      }

      // close loading spinner
      //setTimeout(() => setInitLoading(false), 1200)

      // const totalArray = year ? countOneYear : countAll
      // const totalNow = getTotalNow(totalArray, btype)

      // setTotal(totalNow)
    }
    const failCallback = () => {
      console.log('fail')
    }
    const debug = false

    requestToServer(
      url,
      method,
      body,
      verify,
      successCallback,
      failCallback,
      debug
    )
  }

  const loadInit = () => {
    // open spinner
    setInitLoading(true)

    let region = '信義區'
    let postcode = '110'

    if (townshipOrStation != null) {
      region = townshipOrStation.name
      postcode = townshipOrStation.postcode
    }

    const type = searchType ? searchType : 1
    const coordinate = townshipOrStation
      ? townshipOrStation.lat + ',' + townshipOrStation.lng
      : ''
    const path = type === 1 || type === 9 ? 'list' : 'clist'

    // cursor set to 0
    const year = keytypeGlobal === 'all' ? 0 : 1

    // do y-m range query
    const startDate = `${rangeQuery.from.year}-${
      rangeQuery.from.month < 10
        ? '0' + rangeQuery.from.month
        : rangeQuery.from.month
    }-01`

    const endDate = `${rangeQuery.to.year}-${
      rangeQuery.to.month < 10 ? '0' + rangeQuery.to.month : rangeQuery.to.month
    }-01`

    const query = `region=${region}&postcode=${postcode}&year=${year}&type=${type}&street=${street}&orderby=${orderby}&orderdirection=${orderdirection}&start=0&btype=${btype}&coordinate=${coordinate}&startDate=${startDate}&endDate=${endDate}`
    const url = `${hostUrl}/realestates/${path}/?${query}`

    //console.log(query)

    const method = 'GET'
    const body = ''
    const verify = () => true
    const successCallback = (data) => {
      //setDataAllList(data.rows)
      //setLoading(false)
      if (data.status) {
        setDataAllList(data.rows)
        setCursor(50)
      } else {
        setDataAllList([])
        setCursor(0)
      }
      // every rows is increment 50

      // const totalArray = year ? countOneYear : countAll
      // const totalNow = getTotalNow(totalArray, btype)
      // console.log(totalArray, totalNow)
      // setTotal(totalNow)
      setTimeout(() => setInitLoading(false), 1000)
    }
    const failCallback = () => {
      console.log('fail')
    }
    const debug = false

    requestToServer(
      url,
      method,
      body,
      verify,
      successCallback,
      failCallback,
      debug
    )
  }

  // did mount
  // if btype orderby orderdirection change
  // reset data
  useEffect(() => {
    // FIXME: no data not to init
    if (countAll || countOneYear) loadInit()
  }, [btype, orderby, orderdirection, countAll, countOneYear, rangeQuery])

  // if keytypeGlobal change, change orderby
  useEffect(() => {
    setOrderby('transaction_date')
    setOrderdirection('desc')
  }, [keytypeGlobal])

  const replaceAll = (str, find, replace) =>
    str.replace(new RegExp(find, 'g'), replace)

  const handleOrder = (index) => {
    // orderby = transaction_date, total_price,
    // unit_price, address, building_type, age,
    // total_area, total_transfer_area
    // default transaction_date
    const orderbyRows = [
      'transaction_date',
      'building_type',
      'address',
      'total_price',
      'unit_price',
      'total_transfer_area',
      'total_area',
      'age',
      'v_transfer_hierarchy',
    ]

    if (orderby !== orderbyRows[index]) {
      setOrderby(orderbyRows[index])
    }

    //switch direction
    if (orderby === orderbyRows[index]) {
      const newOrderdirection =
        orderdirection === 'desc' || orderdirection === '' ? 'asc' : 'desc'
      setOrderdirection(newOrderdirection)
    }

    //scroll to header
    scrollToTable()
  }

  const shouldShowOrderIcon = (index) => {
    const orderbyRows = [
      'transaction_date',
      'building_type',
      'address',
      'total_price',
      'unit_price',
      'total_transfer_area',
      'total_area',
      'age',
      'v_transfer_hierarchy',
    ]

    if (orderby === orderbyRows[index]) return true
    return false
  }

  const scrollToTable = () => {
    scroller.scrollTo('myScrollToElement', {
      // duration: 700,
      // delay: 200,
      smooth: true,
      offset: -80, // Scrolls to element + 50 pixels down the page
    })
  }

  const splitAddress = (address, township) => {
    const addressArray = address.split(township)
    if (addressArray.length > 1) {
      return addressArray
    }
    return ['', ...addressArray]
  }

  const headerItem = (title, index) => {
    return (
      <Button
        style={{ padding: '0.25rem' }}
        variant="link"
        size="sm"
        onClick={() => handleOrder(index)}
      >
        <span className="btn-list-header">{title}</span>
        <OrderDirectionIcon
          orderdirection={orderdirection}
          show={shouldShowOrderIcon(index)}
        />
      </Button>
    )
  }

  const spinner = (
    <div className="row h-100 justify-content-center align-items-center">
      <div className="sweet-loading">
        <ScaleLoader
          css={override}
          size={60}
          color={'#0070bd'}
          loading={initLoading}
        />
      </div>
      <h4 className="p-3" style={{ color: '#0070bd' }}>
        資料查詢中
      </h4>
    </div>
  )

  const initSpinner = (
    <div
      style={{ height: '50vh' }}
      className="row h-100 justify-content-center align-items-center"
    >
      <div className="sweet-loading">
        <ScaleLoader
          css={override}
          size={60}
          color={'#0070bd'}
          loading={initLoading}
        />
      </div>
      <h4 className="p-3" style={{ color: '#0070bd' }}>
        資料查詢中
      </h4>
    </div>
  )

  const dataHeading = (
    <Sticky top={matchPoints.xs ? 167 : 125}>
      <div
        className="d-flex flex-row bd-highlight"
        style={{
          backgroundColor: '#fff',
          borderBottom: '2px solid #aaa',
        }}
      >
        <div
          className={`p-2 date-w ${
            matchPoints.xs ? '' : 'flex-fill'
          } bd-highlight font-weight-bold`}
        >
          {headerItem('期間', 0)}
        </div>
        {/* hidden only in xs */}
        <div className="d-none d-sm-block p-2 w-15  flex-fill bd-highlight font-weight-bold">
          {headerItem('型態', 1)}
        </div>
        <div
          className={`p-2 address-w ${
            matchPoints.xs ? '' : 'flex-fill'
          } bd-highlight font-weight-bold`}
        >
          {headerItem('地址', 2)}
        </div>
        <div className="d-none d-sm-block p-2 w-10  flex-fill bd-highlight font-weight-bold text-right">
          {headerItem('成交價', 3)}
        </div>
        <div className="d-none d-sm-block p-2 w-15  flex-fill bd-highlight font-weight-bold text-right">
          {headerItem('單價', 4)}
        </div>
        {/* hidden only in xs */}
        <div className="d-none d-sm-block p-2 w-10  flex-fill bd-highlight font-weight-bold text-right">
          {headerItem('建坪', 5)}
        </div>
        <div className="d-none d-sm-block p-2 w-7-5  flex-fill bd-highlight font-weight-bold text-right">
          {headerItem('地坪', 6)}
        </div>
        <div className="d-none d-sm-block p-2 w-7-5  flex-fill bd-highlight font-weight-bold text-right">
          {headerItem('屋齡', 7)}
        </div>
        <div className="d-none d-sm-block p-2 w-7-5  flex-fill bd-highlight font-weight-bold text-right">
          {headerItem('樓層', 8)}
        </div>
      </div>
    </Sticky>
  )

  const dataTable = dataAllList.map((v, i) => {
    let parkingAreaNote = ''
    if (v.valid.includes('3') && +v.parking_space_total_area) {
      parkingAreaNote = (
        <div className="unit">
          含車位
          {(+v.parking_space_total_area).toFixed(2)}坪
        </div>
      )
    }

    if (v.valid.includes('3') && !+v.parking_space_total_area)
      parkingAreaNote = <div className="unit">含車位坪數</div>

    let parkingPriceNote =
      v.valid.includes('2') && +v.parking_space_total_price ? (
        <div className="unit">
          含車位
          {(+v.parking_space_total_price / 10000).toFixed(1)}萬
        </div>
      ) : (
        ''
      )

    if (v.valid.includes('2') && !+v.parking_space_total_price)
      parkingPriceNote = <div className="unit">含車位價</div>

    const parkingIncludedNote =
      v.valid.includes('2') || v.valid.includes('3') ? (
        <div className="unit">含車位</div>
      ) : (
        ''
      )

    const max = keytypeGlobal === 'all' ? maxAll : maxOneYear
    const min = keytypeGlobal === 'all' ? minAll : minOneYear

    let uniPriceNote =
      +v.unit_price > max || +v.unit_price < min ? (
        <OverlayTrigger
          key="top"
          placement="top"
          overlay={
            <Tooltip id={`tooltip-top`}>
              單價並未落於 {(min / 10000).toFixed(2)}~{(max / 10000).toFixed(2)}{' '}
              萬元/坪之間
            </Tooltip>
          }
        >
          <div className="unit">落於標準差外</div>
        </OverlayTrigger>
      ) : (
        ''
      )

    if (v.valid.includes('2') || v.valid.includes('3'))
      uniPriceNote = <div className="unit">車位未拆分</div>

    if (v.valid.includes('4'))
      uniPriceNote = <div className="unit">親屬或關係人交易</div>

    const tdateString = v.transaction_date.substr(0, 7)
    const tdate = (
      <>
        <div className="dealzone-year">{tdateString.split('-')[0]}</div>
        <div className="dealzone-month">
          {tdateString.split('-')[1]}
          <span className="unit pl-1">月</span>
        </div>
      </>
    )
    const btype = (
      <>
        {v.building_type.split('(')[0]}
        {parkingIncludedNote}
      </>
    )

    const totalPrice = (
      <>
        <span className="price">{(+v.total_price / 10000).toFixed(1)}</span>
        <span className="unit pl-1">萬</span>
        {parkingPriceNote}
      </>
    )

    const unitPrice = (
      <>
        <span className="price">{(+v.unit_price / 10000).toFixed(2)}</span>
        <span className="unit pl-1">萬/坪</span>
        {uniPriceNote}
      </>
    )
    const totalArea = (
      <>
        {(+v.total_area).toFixed(2)}
        <span className="unit pl-1">坪</span>
      </>
    )
    const totalTransferArea = (
      <>
        {(+v.total_transfer_area).toFixed(2)}
        <span className="unit pl-1">坪</span>
        {parkingAreaNote}
      </>
    )
    let ageInfo = v.age ? (
      <>
        {v.age}
        <span className="unit pl-1">年</span>
      </>
    ) : (
      ''
    )

    if (+v.age < 0) ageInfo = '預售'

    const floorInfo = v.v_transfer_hierarchy && (
      <>
        {v.v_transfer_hierarchy === 'all' ? '全' : v.v_transfer_hierarchy}
        <div className="unit pl-1">共{v.v_total_floors}層</div>
      </>
    )

    // address is the latest one cause it need to open detail modal
    const addressArray = splitAddress(v.address, v.township)
    let addressTitle = replaceAll(v.address, countryOrLine, '')
    addressTitle = replaceAll(addressTitle, v.township, '')
    //addressArray.length < 3 ? addressArray[1] : addressArray[2]
    const addressTownship = addressArray[0] + v.township
    const addressInfo = (
      <>
        <Button
          variant="link"
          size="sm"
          style={{
            padding: '0.1rem',
            borderWidth: 0,
            background: 'transparent',
            textAlign: 'left',
          }}
          onClick={() => {
            // const detailData = {
            //   ...v,
            // }
            setShow(true)
            setDetailData(v)
          }}
        >
          <span className="btn-list-header">{addressTitle}</span>
        </Button>
        <div className="unit">{addressTownship}</div>
      </>
    )
    return (
      <div
        key={cursor * 100 + i}
        className="d-flex flex-row bd-highlight "
        style={{
          borderBottom: '1px solid #aaa',
          backgroundColor:
            +v.valid !== 1 || +v.unit_price > max || +v.unit_price < min
              ? '#f7eef0'
              : '#fff',
        }}
      >
        <div
          className={`p-2 date-w ${
            matchPoints.xs ? '' : 'flex-fill'
          } bd-highlight`}
        >
          {tdate}
        </div>
        {/* hidden only in xs */}
        <div className="d-none d-sm-block p-2 w-15 flex-fill bd-highlight">
          {btype}
        </div>
        <div
          className={`p-2 address-w text-left ${
            matchPoints.xs ? '' : 'flex-fill'
          } bd-highlight`}
        >
          {addressInfo}
          <div className="d-block d-sm-none">類型：{btype}</div>
          <div className="d-block d-sm-none d-flex flex-row">
            <div className="pr-3 pt-2 pb-2">成交價：{totalPrice}</div>
            <div className="pr-0 pt-2 pb-2">單價：{unitPrice}</div>
          </div>
          <div className="d-block d-sm-none d-flex flex-row">
            <div className="pr-3 pt-2 pb-2">建坪：{totalTransferArea}</div>
            <div className="pr-0 pt-2 pb-2">地坪：{totalArea}</div>
          </div>
          <div className="d-block d-sm-none d-flex flex-row">
            <div className="pr-3 pt-2 pb-2">屋齡：{ageInfo}</div>
          </div>
          <div className="d-block d-sm-none d-flex flex-row">
            <div className="pr-3 pt-2 pb-2">樓層：{floorInfo}</div>
          </div>
        </div>
        <div className="d-none d-sm-block p-2 w-10  flex-fill bd-highlight  text-right">
          {totalPrice}
        </div>
        <div className="d-none d-sm-block p-2 w-15  flex-fill bd-highlight  text-right">
          {unitPrice}
        </div>
        {/* hidden only in xs */}
        <div className="d-none d-sm-block p-2 w-10  flex-fill bd-highlight  text-right">
          {totalTransferArea}
        </div>
        <div className="d-none d-sm-block p-2 w-7-5  flex-fill bd-highlight  text-right">
          {totalArea}
        </div>
        <div className="d-none d-sm-block p-2 w-7-5  flex-fill bd-highlight  text-right">
          {ageInfo}
        </div>
        <div className="d-none d-sm-block p-2 w-7-5  flex-fill bd-highlight text-right">
          {floorInfo}
        </div>
      </div>
    )
  })

  // let the spinner show when loading data in slow network

  if (initLoading) {
    return (
      <>
        <h3
          className="pt-2 pm-2"
          style={{
            color: 'rgba(190,188,188,1)',
          }}
        >
          區域行情
        </h3>
        <DetailModal show={show} setShow={setShow} detailData={detailData} />
        {dataHeading}
        {spinner}
      </>
    )
  }

  return (
    <>
      <h3
        className="pt-2 pm-2"
        style={{
          color: 'rgba(190,188,188,1)',
        }}
      >
        區域行情
      </h3>
      <DetailModal show={show} setShow={setShow} detailData={detailData} />
      {dataHeading}
      <InfiniteScroll
        dataLength={dataTable.length} //This is important field to render the next data
        next={loadMore}
        hasMore={cursor > 0 && cursor < 2000 && cursor < total}
        loader={spinner}
        endMessage={
          <Alert
            variant="info"
            style={{
              textAlign: 'center',
            }}
          >
            {cursor > total
              ? '已無更多資料'
              : cursor > 0
              ? '已超過2千筆'
              : '無資料'}
          </Alert>
        }
      >
        {dataAllList.length > 0 ? dataTable : ''}
      </InfiniteScroll>
    </>
  )
}
