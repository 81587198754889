import React, { useState, useEffect, useCallback } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  useHistory,
} from 'react-router-dom'
import Geocode from 'react-geocode'
import { appConfig } from './config'

import MyNavbar from './components/MyNavbar'
import FixedTopPanel from './components/FixedTopPanel'
import MyFooter from './components/MyFooter'
import MainContent from './components/MainContent'

import NotFoundPage from './pages/NotFoundPage'

import ProtectedRoute from './utils/ProtectedRoute'

import Home from './pages/Home'
import List from './pages/List'
import Detail from './pages/Detail'
import About from './pages/About'
import ScrollToTop from './components/ScrollToTop'
import LatLng from './pages/LatLng'

function App() {
  const [area, setArea] = useState('縣市區域')
  const [townshipOrStation, setTownshipOrStation] = useState(null)

  const [countryOrLine, setCountryOrLine] = useState('')
  const [street, setStreet] = useState('')
  const [streetIsNeed, setStreetIsNeed] = useState('')
  // 1 = area & street
  // 235 = coordinate
  const [searchType, setSearchType] = useState(0)
  const [lat, setLat] = useState(0)
  const [lng, setLng] = useState(0)
  const [goList, setGoList] = useState()

  //check all search if valid
  const [searchIsValid, setSearchIsValid] = useState(false)

  //use for tab township
  const [country, setCountry] = useState(-1)
  const [township, setTownship] = useState(-1)
  const [postcode, setPostcode] = useState(-1)

  // use for tab mrt
  const [line, setLine] = useState(-1)
  const [station, setStation] = useState(-1)
  const [variant, setVariant] = useState('link')

  let history = useHistory()

  useEffect(() => {
    if (searchType !== 1 && searchType && setSearchIsValid) {
      setStreetIsNeed(street)
    } else {
      setStreetIsNeed('')
    }
  }, [street, searchType, setSearchIsValid])

  const getLatLngFromStreet = async (callback) => {
    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    Geocode.setApiKey(appConfig.googleMapApiKey)
    // set response language. Defaults to english.
    Geocode.setLanguage('zh-TW')

    Geocode.setRegion('tw')
    // Enable or disable logs. Its optional.
    //Geocode.enableDebug()
    // Get latidude & longitude from address.
    try {
      const address = countryOrLine + townshipOrStation.name + street

      const response = await Geocode.fromAddress(address)

      const { lat, lng } = response.results[0].geometry.location
      const data = {
        name: townshipOrStation.name,
        address: address,
        postcode: townshipOrStation.postcode,
        lat: lat,
        lng: lng,
      }
      //console.log(data)
      setTownshipOrStation(data)
      setLat(lat)
      setLng(lng)

      setGoList(() => () => callback())
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (lat && lng && searchType > 0) {
      setSearchIsValid(true)
    }
  }, [lat, lng, searchType])

  useEffect(() => {
    if (goList && searchIsValid) {
      goList()
      //setGoList(false)
    }
  }, [goList, searchIsValid])

  return (
    <Router>
      <>
        <MyNavbar />
        <FixedTopPanel
          area={area}
          townshipOrStation={townshipOrStation}
          countryOrLine={countryOrLine}
          searchType={searchType}
          street={street}
          streetIsNeed={streetIsNeed}
        />
        {/* ScrollToTop是為了讓連到另一頁內容時，頁面回到最上方 */}
        <ScrollToTop>
          <MainContent>
            <Switch>
              <Route path="/rlist">
                <List
                  area={area}
                  townshipOrStation={townshipOrStation}
                  countryOrLine={countryOrLine}
                  searchType={searchType}
                  street={street}
                />
              </Route>
              <Route path="/detail">
                <Detail />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/latlng">
                <LatLng />
              </Route>

              <Route exact path="/">
                <Home
                  area={area}
                  setArea={setArea}
                  townshipOrStation={townshipOrStation}
                  setTownshipOrStation={setTownshipOrStation}
                  countryOrLine={countryOrLine}
                  setCountryOrLine={setCountryOrLine}
                  street={street}
                  setStreet={setStreet}
                  searchType={searchType}
                  setSearchType={setSearchType}
                  country={country}
                  setCountry={setCountry}
                  township={township}
                  setTownship={setTownship}
                  postcode={postcode}
                  setPostcode={setPostcode}
                  line={line}
                  setLine={setLine}
                  station={station}
                  setStation={setStation}
                  variant={variant}
                  setVariant={setVariant}
                  setSearchIsValid={setSearchIsValid}
                  getLatLngFromStreet={getLatLngFromStreet}
                />
              </Route>
              <Route exact path="*">
                <NotFoundPage />
              </Route>
            </Switch>
          </MainContent>
        </ScrollToTop>
        <MyFooter />
      </>
    </Router>
  )
}

export default App
