import React, { useState, useRef } from 'react'
import { OverlayTrigger, Popover, Button } from 'react-bootstrap'
import { BsInfo, BsInfoCircleFill } from 'react-icons/bs'

import { MdInfo, MdInfoOutline } from 'react-icons/md'
import { AiFillInfoCircle } from 'react-icons/ai'

import infoIcon from '../icon-info.svg'

export default function TooltipIconButton(props) {
  const { title, onClick, variant, clicked } = props
  // const [show, setShow] = useState(false)
  // const target = useRef(null)

  const { name, info } = props
  return (
    <>
      <OverlayTrigger
        trigger={['hover', 'focus', 'click']}
        key={name}
        delay={700}
        placement="top"
        overlay={
          <Popover id={`popover-positioned-top`}>
            <Popover.Title as="h6">{name}</Popover.Title>
            <Popover.Content>{info}</Popover.Content>
          </Popover>
        }
      >
        <Button
          className="mr-2 mt-2 mb-2"
          style={{ borderLeftWidth: 0 }}
          //onClick={onClick}
          variant={variant ? variant : 'link'}
        >
          {/* <img
            src={infoIcon}
            alt="info"
            style={{ vericalAlign: 'middle', maxWidth: 24 }}
          /> */}
          <AiFillInfoCircle style={{ fontSize: 18, verticalAlign: 'middle' }} />
        </Button>
      </OverlayTrigger>
    </>
  )
}
