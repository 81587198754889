import React, { useEffect, useState } from 'react'
import Geocode from 'react-geocode'
import { appConfig } from '../config'

//import { lines, stations } from '../data/tpemrt'

//import { lines, stations } from '../data/tpemrt-future'

//import { lines, stations } from '../data/tyhmrt'

//import { lines, stations } from '../data/tyhmrt-future'

//import { lines, stations } from '../data/khcmrt'

import { lines, stations } from '../data/tccmrt-future'

export default function LatLng() {
  const [mystations, setMystations] = useState(stations)

  // const sleeper = (ms) => (x) =>
  //   new Promise((resolve) => setTimeout(() => resolve(x), ms))

  useEffect(() => {
    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    Geocode.setApiKey(appConfig.googleMapApiKey)

    // set response language. Defaults to english.
    Geocode.setLanguage('en')

    // Enable or disable logs. Its optional.
    //Geocode.enableDebug()

    //console.log(JSON.stringify(stations))
    for (let i = 0; i < lines.length; i++) {
      for (let j = 0; j < stations[i].length; j++) {
        //if (stations[i][j].lat) continue

        // Get latidude & longitude from address.
        if (stations[i][j].address && !stations[i][j].lat) {
          setTimeout(
            () =>
              Geocode.fromAddress(stations[i][j].address)
                .then(
                  (response) => {
                    const { lat, lng } = response.results[0].geometry.location
                    stations[i][j].lat = lat
                    stations[i][j].lng = lng
                    //console.log(stations[i][j])
                  },
                  (error) => {
                    console.error(error)
                  }
                )
                .then(() => {
                  //setMystations(stations)
                  console.log(JSON.stringify(stations))
                  //console.log('done!')
                }),
            2000
          )
        }
      }
    }
  }, [])

  useEffect(() => {
    //console.log(JSON.stringify(mystations))
  }, [mystations])

  return <></>
}
