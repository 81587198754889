import React, { useState, useEffect } from 'react'

function MyFooter(props) {
  return (
    <>
      <footer className="footer mt-auto py-3">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              © 2018 - 2020 house123. All Rights Reserved. 法律顧問:
              寶德楊法律事務所洪國誌律師
            </div>

            <div className="col-sm-4">
              <ul className="nav justify-content-end">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    target="_blank"
                    rel="noreferrer"
                    href="https://house123.com.tw/index.php/%E4%BD%BF%E7%94%A8%E8%80%85%E6%A2%9D%E6%AC%BE"
                  >
                    使用者條款
                  </a>
                </li>
                <li class="footer-item">｜</li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://house123.com.tw/index.php/%E9%9A%B1%E7%A7%81%E6%AC%8A%E6%94%BF%E7%AD%96"
                  >
                    隱私權政策
                  </a>
                </li>
                <li class="footer-item">｜</li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://house123.com.tw/index.php/%E5%B8%B8%E8%A6%8B%E5%95%8F%E9%A1%8C"
                  >
                    常見問題
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default MyFooter
