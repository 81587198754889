import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import DetailData from '../samples/DetailData'

import '../styles/list.scss'

function Detail(props) {
  //console.log(props)
  return (
    <>
      <DetailData />
    </>
  )
}

export default withRouter(Detail)
