import React, { useRef, useEffect } from 'react'
import { Button } from 'react-bootstrap'

export default function ButtonChoose(props) {
  const { title, onClick, variant, clicked, style } = props
  //const inputRef = useRef(null)

  // split title if have space
  let titleFormat = title
  if (title.includes(' ')) {
    const titleBefore = title.split(' ')[0]
    const titleAfter = title.split(' ')[1]
    titleFormat = (
      <>
        {titleBefore} <small>{titleAfter}</small>
      </>
    )
  }

  return (
    <>
      <Button
        className="ml-2 mt-2 mb-2"
        style={style}
        onClick={onClick}
        variant={variant ? variant : 'link'}
      >
        {titleFormat}
      </Button>
    </>
  )
}
