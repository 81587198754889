import React, { useState, useEffect } from 'react'
import ButtonChoose from './ButtonChoose'

//import { lines, stations } from '../data/khcmrt'

import { BsInfoCircleFill } from 'react-icons/bs'
import { Alert, ButtonGroup } from 'react-bootstrap'
import TooltipIconButton from './TooltipIconButton'

export default function TabMrt(props) {
  //console.log(props)
  //const countries = Object.getOwnPropertyNames(data)

  //const [buttonChoose, setButtonChoose] = useState('')
  const [variant, setVariant] = useState('link')
  // set country or Twonship for only index
  const [line, setLine] = useState(-1)
  const [station, setStation] = useState(-1)

  const {
    lines,
    stations,
    keyChange,
    setTownshipOrStation,
    setCountryOrLine,
    // line,
    // setLine,
    // station,
    // setStation,
    area,
    myArea,
  } = props

  // if key chnage(click other tab), then reset
  useEffect(() => {
    if (area !== myArea) {
      setLine(-1)
      setStation(-1)
      setVariant(props.variant)
    }
  }, [area])

  useEffect(() => {
    if (area === myArea) {
      setLine(props.line)
      setStation(props.station)
      setVariant(props.variant)
    }

    // setStation(-1)
    // setTownshipOrStation('')
    // setCountryOrLine('')
    // console.log(lines)
    // console.log(lines)
    // console.log(stations)
    // console.log(station)
  }, [])

  const AlertChooseLineFirst = (
    <Alert variant="danger">
      <BsInfoCircleFill /> 請先選擇站點
    </Alert>
  )

  // const handleButtonClick = (index) => {
  //   const newVariant = [...variant]
  //   newVariant[index] = toggleVariant(newVariant[index])
  //   setVariant(newVariant)
  // }

  //const toggleVariant = (variant) => (variant === 'link' ? 'primary' : 'link')

  // const title = ['台北市', '新北市']
  //const variant = ['link']

  return (
    <>
      <h5 className="pt-4" style={{ color: 'rgba(190,188,188,1)' }}>
        路線
      </h5>
      {lines.map((v, i) => {
        return v.info ? (
          <ButtonGroup key={v.name + i}>
            <ButtonChoose
              title={v.name}
              style={{ borderRightWidth: 0 }}
              variant={line === i ? variant : 'link-' + v.variant}
              onClick={() => {
                setLine(i)
                setCountryOrLine(v.name)
                setVariant(v.variant)

                //reset station when click country
                setStation(-1)
                setTownshipOrStation(null)

                if (area === myArea) {
                  props.setLine(i)
                  props.setVariant(v.variant)
                }
              }}
            />
            <TooltipIconButton
              variant={line === i ? variant : 'link-' + v.variant}
              name={v.name}
              info={v.info}
            />
          </ButtonGroup>
        ) : (
          <ButtonChoose
            key={i + v.name}
            title={v.name}
            variant={line === i ? variant : 'link-' + v.variant}
            onClick={() => {
              setLine(i)
              setCountryOrLine(v.name)
              setVariant(v.variant)

              //reset station when click country
              setStation(-1)
              setTownshipOrStation(null)

              if (area === myArea) {
                props.setLine(i)
                props.setVariant(v.variant)
              }
            }}
          />
        )
      })}
      <hr />
      <h5 className="" style={{ color: 'rgba(190,188,188,1)' }}>
        站點
      </h5>
      {line > -1
        ? stations[line].map((v, i) => {
            return (
              <ButtonChoose
                key={v.name + i}
                title={v.name}
                variant={
                  station === i ? variant : 'link-' + lines[line].variant
                }
                onClick={() => {
                  setStation(i)
                  setTownshipOrStation(v)
                  if (area === myArea) {
                    props.setStation(i)
                  }
                }}
              />
            )
          })
        : AlertChooseLineFirst}
    </>
  )
}
