import React from 'react'

import MapContainer from '../components/gmap/MapContainer'

export default function DetailData() {
  return (
    <>
      <div className="modal-header">
        <div className="unit ng-binding">台北市北投區</div>
        <h4 className="ng-binding">知行路 271-300 號</h4>
      </div>
      <div className="modal-body">
        <MapContainer />
        <table className="table table-condensed">
          <tbody>
            <tr>
              <th>交易日期</th>
              <td className="ng-binding">2020 年 1 月</td>
            </tr>
            <tr>
              <th>總價</th>
              <td>
                <span className="price ng-binding">
                  2,070<span className="price-point ng-binding">.00</span>
                </span>
                <span className="unit">萬元</span>
              </td>
            </tr>
            <tr>
              <th>單價</th>
              <td>
                <span ng-show="record.object_price_unit" className="ng-hide">
                  <span className="price ng-binding">
                    <span className="price-point ng-binding">NaN</span>
                  </span>
                </span>
                <span ng-show="!record.object_price_unit" className="">
                  <span className="price ng-binding">
                    38<span className="price-point ng-binding">.50</span>
                  </span>
                </span>
                <span className="unit">萬元</span>
              </td>
            </tr>
            <tr>
              <th>建物移轉總面積</th>
              <td className="ng-binding">
                55.16
                <span className="unit">坪</span>
              </td>
            </tr>
            <tr>
              <th>土地移轉面積</th>
              <td className="ng-binding">
                8.81
                <span className="unit">坪</span>
              </td>
            </tr>
            <tr>
              <th>交易標的</th>
              <td className="ng-binding">房地(土地+建物)+車位</td>
            </tr>
            <tr ng-show="record.usage_city" className="">
              <th>使用分區或編定</th>
              <td className="ng-binding">商</td>
            </tr>
            <tr ng-show="record.usage_non_city" className="ng-hide">
              <th>非都市土地使用分區</th>
              <td className="ng-binding"></td>
            </tr>
            <tr ng-show="record.usage_non_city_other" className="ng-hide">
              <th>非都市土地使用地</th>
              <td className="ng-binding"></td>
            </tr>
            <tr>
              <th>移轉層次 / 總樓層數</th>
              <td className="ng-binding">九層,夾層 / 9</td>
            </tr>
            <tr>
              <th>交易筆棟數</th>
              <td>
                <span className="grouped ng-binding">1 土地</span>
                <span className="grouped ng-binding">1 建物</span>
                <span className="grouped ng-binding">1 車位</span>
              </td>
            </tr>
            <tr>
              <th>建物型態</th>
              <td className="ng-binding">華廈(10層含以下有電梯)</td>
            </tr>
            <tr>
              <th>主要用途</th>
              <td className="ng-binding">住家用</td>
            </tr>
            <tr>
              <th>主要建材</th>
              <td className="ng-binding">鋼筋混凝土造</td>
            </tr>
            <tr>
              <th>建築完成日期</th>
              <td className="ng-binding">2005 年 8 月</td>
            </tr>
            <tr>
              <th>建築現況格局</th>
              <td>
                <span className="grouped ng-binding">4 房</span>
                <span className="grouped ng-binding">2 廳</span>
                <span className="grouped ng-binding">2 衛</span>
              </td>
            </tr>
            <tr ng-show="record.parking_type" className="">
              <th>車位類別</th>
              <td className="ng-binding">升降平面</td>
            </tr>
            <tr ng-show="record.price_parking" className="ng-hide">
              <th>車位總價</th>
              <td className="ng-binding">
                0.00
                <span className="unit">萬元</span>
              </td>
            </tr>
            <tr ng-show="record.parking_area" className="">
              <th>車位移轉總面積</th>
              <td className="ng-binding">
                9.00
                <span className="unit">坪</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}
