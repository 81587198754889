// tyhmrt-v1: #8246AF

export const lines = [{ name: '機場線', variant: 'tyhmrt-v1' }]

export const stations = [
  [
    {
      name: '山鼻 A10',
      address: '桃園市蘆竹區南山路三段155號',
      lat: 25.0808557,
      lng: 121.2853437,
    },
    {
      name: '坑口 A11',
      address: '桃園市蘆竹區坑菓路460號',
      lat: 25.0879248,
      lng: 121.2656025,
    },
    {
      name: '機場第一航廈 A12',
      address: '桃園市大園區航站南路17之1號',
      lat: 25.0817543,
      lng: 121.2391781,
    },
    {
      name: '機場第二航廈 A13',
      address: '桃園縣大園鄉航站南路9號',
      lat: 25.0772482,
      lng: 121.2322312,
    },
    {
      name: '機場第三航廈 A14',
      address: '桃園縣大園鄉航站南路7號',
      lat: 25.0799057,
      lng: 121.2374416,
    },
    {
      name: '機場旅館 A14a',
      address: '桃園市大園區航站南路1之2號',
      lat: 25.0684019,
      lng: 121.220824,
    },
    {
      name: '大園 A15',
      address: '桃園市大園區橫湳一路50號',
      lat: 25.0560632,
      lng: 121.2104866,
    },
    {
      name: '橫山 A16',
      address: '桃園市大園區大竹南路1180號',
      lat: 25.0420697,
      lng: 121.2391829,
    },
    {
      name: '領航 A17',
      address: '桃園市大園區領航北路四段351號',
      lat: 25.0236542,
      lng: 121.2208692,
    },
    {
      name: '高鐵桃園站 A18',
      address: '桃園市中壢區高鐵北路一段5號 ',
      lat: 25.0129522,
      lng: 121.2148454,
    },
    {
      name: '桃園體育園區 A19',
      address: '桃園市中壢區高鐵南路二段350號',
      lat: 25.0029931,
      lng: 121.2046581,
    },
    {
      name: '興南 A20',
      address: '桃園市中壢區中豐北路一段685號',
      lat: 24.980517,
      lng: 121.2159363,
    },
    {
      name: '環北 A21',
      address: '桃園市中壢區中豐北路一段26號',
      lat: 24.9676191,
      lng: 121.2211107,
    },
  ],
]
