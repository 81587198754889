import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import MyBanner from '../components/MyBanner'
import MyBreadcrumb from '../components/MyBreadcrumb'

function About(props) {
  // console.log(props)

  return (
    <>
      <MyBanner title="使用說明" lead="首頁是一個網站的第一個看到的頁面" />
      <MyBreadcrumb />
    </>
  )
}

// 高階元件的用法
export default withRouter(About)
