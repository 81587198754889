// khcmrt-v1: #e20b65
// khcmrt-v2: #faa73f

export const lines = [
  { name: '紅線', variant: 'khcmrt-v1' },
  { name: '橘線', variant: 'khcmrt-v2' },
]

export const stations = [
         [
           {
             name: '南岡山 R24',
             address: '高雄市岡山區中山南路2號',
             lat: 22.7808454,
             lng: 120.2992429,
           },
           {
             name: '橋頭火車站 R23',
             address: '高雄市橋頭區站前街12之1號',
             lat: 22.7607501,
             lng: 120.3098085,
           },
           {
             name: '橋頭糖廠 R22a',
             address: '高雄市橋頭區興糖路19號',
             lat: 22.7536944,
             lng: 120.3152486,
           },
           {
             name: '青埔(高科大) R22',
             address: '高雄市橋頭區經武路20號',
             lat: 22.7428787,
             lng: 120.3163798,
           },
           {
             name: '都會公園 R21',
             address: '高雄市楠梓區高楠公路1835號',
             lat: 22.7287562,
             lng: 120.320903,
           },
           {
             name: '後勁(海科大) R20',
             address: '高雄市楠梓區加昌路178號',
             lat: 22.7223571,
             lng: 120.316073,
           },
           {
             name: '楠梓加工區 R19',
             address: '高雄市楠梓區加昌路598號',
             lat: 22.7181072,
             lng: 120.3052964,
           },
           {
             name: '油廠國小(中山大學附中) R18',
             address: '高雄市楠梓區左楠路6號',
             lat: 22.7119515,
             lng: 120.3023789,
           },
           {
             name: '世運(國家體育園區) R17',
             address: '高雄市楠梓區左楠路1號',
             lat: 22.713195,
             lng: 120.3027958,
           },
           {
             name: '左營(高鐵) R16',
             address: '高雄市左營區高鐵路107號',
             lat: 22.6879531,
             lng: 120.3090642,
           },
           {
             name: '生態園區 R15',
             address: '高雄市左營區博愛三路435號',
             lat: 22.6776059,
             lng: 120.3066117,
           },
           {
             name: '巨蛋(三民家商) R14',
             address: '高雄市左營區博愛二路485號',
             lat: 22.6663552,
             lng: 120.3031353,
           },
           {
             name: '凹子底 R13',
             address: '高雄市鼓山區博愛二路21號',
             lat: 22.6605936,
             lng: 120.3030473,
           },
           {
             name: '後驛(高醫大) R12',
             address: '高雄市三民區博愛一路220號',
             lat: 22.6474703,
             lng: 120.3033953,
           },
           {
             name: '高雄車站 R11',
             address: '高雄市三民區建國二路320號',
             lat: 22.6379834,
             lng: 120.302821,
           },
           {
             name: '美麗島 R10',
             address: '高雄市新興區中山一路115號',
             lat: 22.631517,
             lng: 120.3013294,
           },
           {
             name: '中央公園 R09',
             address: '高雄市前金區中山一路11號',
             lat: 22.628116,
             lng: 120.300746,
           },
           {
             name: '三多商圈 R08',
             address: '高雄市芩雅區中山二路268號',
             lat: 22.613946,
             lng: 120.305246,
           },
           {
             name: '獅甲(勞工公園) R08',
             address: '高雄市前鎮區中山三路150號',
             lat: 22.6058641,
             lng: 120.3087817,
           },
           {
             name: '凱旋 R07',
             address: '高雄市前鎮區中山三路1號',
             lat: 22.594877,
             lng: 120.3173781,
           },
           {
             name: '前鎮高中(五甲) R05',
             address: '高雄市前鎮區翠亨北路225號',
             lat: 22.5882425,
             lng: 120.3217512,
           },
           {
             name: '草衙(高雄捷運公司) R04a',
             address: '高雄市前鎮區翠亨南路555號',
             lat: 22.5802304,
             lng: 120.328395,
           },
           {
             name: '高雄國際機場 R04',
             address: '高雄市小港區中山四路2之2號',
             lat: 22.5712279,
             lng: 120.3404126,
           },
           {
             name: '小港 R03',
             address: '高雄市小港區沿海一路280號',
             lat: 22.5642304,
             lng: 120.3542307,
           },
         ],
         [
           {
             name: '西子灣 O01',
             address: '高雄市鼓山區臨海二路17-1號',
             lat: 22.622011,
             lng: 120.273422,
           },
           {
             name: '鹽埕埔 O02',
             address: '高雄市鹽埕區大勇路96號',
             lat: 22.624489,
             lng: 120.284621,
           },
           {
             name: '市議會(舊址) O04',
             address: '高雄市前金區中正四路192-1號',
             lat: 22.6290926,
             lng: 120.2932521,
           },
           {
             name: '美麗島 O05',
             address: '高雄市新興區中山一路115號',
             lat: 22.631517,
             lng: 120.3013294,
           },
           {
             name: '信義國小 O06',
             address: '高雄市新興區中正三路32-1號',
             lat: 22.6310617,
             lng: 120.3109904,
           },
           {
             name: '文化中心 O07',
             address: '高雄市苓雅區中正二路45號',
             lat: 22.6300016,
             lng: 120.3182562,
           },
           {
             name: '五塊厝 O08',
             address: '高雄市苓雅區中正一路286號',
             lat: 22.629709,
             lng: 120.3280478,
           },
           {
             name: '技擊館 O09',
             address: '高雄市苓雅區中正一路99號',
             lat: 22.6254698,
             lng: 120.3339431,
           },
           {
             name: '衛武營 O10',
             address: '高雄市苓雅區中正一路2號',
             lat: 22.6258351,
             lng: 120.3410471,
           },
           {
             name: '鳳山西站(高雄市議會) O11',
             address: '高雄市鳳山區自由路281號',
             lat: 22.6253205,
             lng: 120.348894,
           },
           {
             name: '鳳山 O12',
             address: '高雄市鳳山區光遠路446號',
             lat: 22.626164,
             lng: 120.355901,
           },
           {
             name: '大東 O13',
             address: '高雄市鳳山區光遠路226號',
             lat: 22.6255336,
             lng: 120.3636196,
           },
           {
             name: '鳳山國中 O14',
             address: '高雄市鳳山區中山東路225號',
             lat: 22.6242438,
             lng: 120.3707486,
           },
           {
             name: '大寮 OT01',
             address: '高雄市大寮區捷西路300號',
             lat: 22.6220423,
             lng: 120.3890426,
           },
         ],
       ]
