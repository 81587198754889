import React, { useState, useEffect } from 'react'
import ButtonChoose from './ButtonChoose'
import { BsInfoCircleFill } from 'react-icons/bs'
import { Alert } from 'react-bootstrap'

import { countries, townships, postcodes } from '../data/townships'

export default function TabTowship(props) {
  //const countries = Object.getOwnPropertyNames(data)
  // console.log(countries)
  // console.log(townships)

  //const [buttonChoose, setButtonChoose] = useState('')
  //const [variant, setVariant] = useState(['link', 'link'])
  // set country or Twonship
  // const [country, setCountry] = useState(-1)
  // const [township, setTownship] = useState(-1)
  // const [postcode, setPostcode] = useState(-1)

  const {
    keyChange,
    area,
    townshipOrStation,
    setTownshipOrStation,
    countryOrLine,
    setCountryOrLine,
    country,
    setCountry,
    township,
    setTownship,
    postcode,
    setPostcode,
  } = props
  // if key chnage(click other tab), then reset
  // useEffect(() => {
  //   if (area === '縣市區域' && countryOrLine) {
  //     const countryIndex = countries.findIndex((v) => v === countryOrLine)
  //     setCountry(countryIndex)

  //     if (townshipOrStation) {
  //       const townshipIndex = townships.findIndex(
  //         (v) => v[countryIndex] === townshipOrStation.name
  //       )
  //       setTownship(townshipIndex)
  //     }
  //   }

  //   // if (area !== '縣市區域') {
  //   //   setCountry(-1)
  //   //   setTownship(-1)
  //   // }
  // }, [])

  //useEffect(() => {}, [keyChange])

  useEffect(() => {
    // if (country > -1 && township > -1) {
    //   const data = {
    //     name: townships[country][township],
    //     postcode: postcodes[country][township],
    //   }
    //   setTownshipOrStation(data)
    // }
  }, [postcode])
  // const handleButtonClick = (index) => {
  //   const newVariant = [...variant]
  //   newVariant[index] = toggleVariant(newVariant[index])
  //   setVariant(newVariant)
  // }

  //const toggleVariant = (variant) => (variant === 'link' ? 'primary' : 'link')

  // const title = ['台北市', '新北市']
  //const variant = ['link']

  const AlertChooseLineFirst = (
    <Alert variant="danger">
      <BsInfoCircleFill /> 請先選擇縣市
    </Alert>
  )

  return (
    <>
      <h5
        className="pt-4"
        style={{
          color: 'rgba(190,188,188,1)',
        }}
      >
        縣市
      </h5>
      {countries.map((v, i) => {
        return (
          <ButtonChoose
            key={v + i}
            title={v}
            variant={i === country ? 'primary' : 'link'}
            onClick={() => {
              setCountry(i)
              setCountryOrLine(v)
              //reset township when click country
              setTownship(-1)
              setTownshipOrStation(null)
            }}
            clicked={false}
          />
        )
      })}
      <hr />
      <h5
        className=""
        style={{
          color: 'rgba(190,188,188,1)',
        }}
      >
        鄉鎮市區
      </h5>
      {country > -1
        ? townships[country].map((v, i) => {
            return (
              <ButtonChoose
                key={v + i}
                title={v}
                variant={i === township ? 'primary' : 'link'}
                onClick={() => {
                  setTownship(i)
                  setPostcode(i)

                  const data = {
                    name: townships[country][i],
                    postcode: postcodes[country][i],
                  }
                  setTownshipOrStation(data)
                }}
                clicked={false}
              />
            )
          })
        : AlertChooseLineFirst}
    </>
  )
}
