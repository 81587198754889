import React, { Component } from 'react'
//import { Map, GoogleApiWrapper } from 'google-maps-react'
import {
  Map,
  // InfoWindow,
  // Circle,
  Marker,
  GoogleApiWrapper,
} from 'google-maps-react'

import { appConfig } from '../../config'

const mapStyles = {
  width: '100%',
  height: '550px',
}

export class MapListRegion extends Component {
  static defaultProps = {
    lat: 25.0259029,
    lng: 121.5703875,
    //radius: 1000, //meters
  }

  render() {
    //console.log(this.props)
    if (!this.props.lat) return <></>

    return (
      <Map
        google={this.props.google}
        containerStyle={{
          width: '100%',
          height: '550px',
          position: 'relative',
        }}
        zoom={13}
        mapTypeControl={false}
        scaleControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        style={mapStyles}
        initialCenter={{
          lat: this.props.lat,
          lng: this.props.lng,
        }}
        center={{
          lat: this.props.lat,
          lng: this.props.lng,
        }}
      >
        <Marker
          name={'物件位置'}
          position={{ lat: this.props.lat, lng: this.props.lng }}
        />
      </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: appConfig.googleMapApiKey,
  language: 'zh-TW',
})(MapListRegion)
