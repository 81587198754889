import React, { useState, useEffect } from 'react'
import requestToServer from '../utils/requestToServer'
//import { useHistory } from 'react-router-dom'
import { Row, Col, Tab, Tabs, Table } from 'react-bootstrap'

import { css } from '@emotion/core'
import ScaleLoader from 'react-spinners/ScaleLoader'

import MapListCicle from './gmap/MapListCicle'
import MapListRegion from './gmap/MapListRegion'

import { appConfig } from '../config/'

import MonthRange from './MonthRange'
//const hostUrl = appConfig.hostRealUrl
const hostUrl = appConfig.debug ? appConfig.hostUrl : appConfig.hostRealUrl

let Scroll = require('react-scroll')
let Element = Scroll.Element
let scroller = Scroll.scroller

const override = css`
  display: block;
`

export default function ListDataCollectionReal(props) {
  const [dataAll, setDataAll] = useState(null)
  const [dataOneYear, setDataOneYear] = useState(null)

  const [dataAllList, setDataAllList] = useState(null)
  const [dataOneYearList, setDataOneYearLsit] = useState(null)

  //const [keytypeGlobal, setKeytypeGlobal] = useState('all')
  const [loading, setLoading] = useState(false)

  // do year-month-range query
  // to parent
  // const [rangeQuery, setRangeQuery] = useState({
  //   from: {
  //     year: 0,
  //     month: 0,
  //   },
  //   to: {
  //     year: 0,
  //     month: 0,
  //   },
  // })

  // change oneYear title if has date range
  const [oneYearTitle, setOneYearTitle] = useState('一年內')

  //let history = useHistory()

  const {
    area,
    townshipOrStation,
    countryOrLine,
    searchType,
    btype,
    setBtype,
    keytypeGlobal,
    setKeytypeGlobal,
    setMinAll,
    setMaxAll,
    setMinOneYear,
    setMaxOneYear,
    setCountAll,
    setCountOneYear,
    street,
    rangeQuery,
    setRangeQuery,
  } = props

  // did mount to request data from server
  useEffect(() => {
    //open spinner
    setLoading(true)
    //console.log(townshipOrStation)

    //default
    let region = '信義區'
    let postcode = '110'

    if (townshipOrStation) {
      region = townshipOrStation.name
      postcode = townshipOrStation.postcode
    }

    const type = searchType ? searchType : 1
    const coordinate = townshipOrStation
      ? townshipOrStation.lat + ',' + townshipOrStation.lng
      : ''
    const path = type === 1 || type === 9 ? 'summary' : 'csummary'

    const query = `region=${region}&postcode=${postcode}&year=0&type=${type}&street=${street}&coordinate=${coordinate}`

    const url = `${hostUrl}/realestates/${path}/?${query}`
    const method = 'GET'
    const body = ''
    const verify = () => true
    const successCallback = (data) => {
      setDataAll(data)
      setMinAll(data.range.min)
      setMaxAll(data.range.max)

      setCountAll(data.summary)
    }
    const failCallback = () => {
      console.log('fail')
    }
    const debug = false

    requestToServer(
      url,
      method,
      body,
      verify,
      successCallback,
      failCallback,
      debug
    )

    const queryOneYear = `region=${region}&postcode=${postcode}&year=1&type=${type}&street=${street}&coordinate=${coordinate}`
    const urlOneYear = `${hostUrl}/realestates/${path}/?${queryOneYear}`

    const successCallbackOneYear = (data) => {
      setDataOneYear(data)
      setMinOneYear(data.range.min)
      setMaxOneYear(data.range.max)
      // let tag = btype
      // if (btype === 0) tag = 'all'
      // const countAllNow = data.summary['count_type_' + tag]
      // setCountOneYear(countAllNow)
      setCountOneYear(data.summary)
      setTimeout(() => setLoading(false), 1200)
    }

    requestToServer(
      urlOneYear,
      method,
      body,
      verify,
      successCallbackOneYear,
      failCallback,
      debug
    )
  }, [])

  // did update to request data from server if has month query
  useEffect(() => {
    //check
    if (
      !rangeQuery.from.year ||
      !rangeQuery.from.month ||
      !rangeQuery.to.year ||
      !rangeQuery.to.month
    )
      return

    //open spinner
    setLoading(true)
    //console.log(townshipOrStation)

    //default
    let region = '信義區'
    let postcode = '110'

    if (townshipOrStation) {
      region = townshipOrStation.name
      postcode = townshipOrStation.postcode
    }

    const type = searchType ? searchType : 1
    const coordinate = townshipOrStation
      ? townshipOrStation.lat + ',' + townshipOrStation.lng
      : ''
    const path = type === 1 || type === 9 ? 'summary' : 'csummary'

    const query = `region=${region}&postcode=${postcode}&year=0&type=${type}&street=${street}&coordinate=${coordinate}`

    const url = `${hostUrl}/realestates/${path}/?${query}`
    const method = 'GET'
    const body = ''
    const verify = () => true
    const successCallback = (data) => {
      setDataAll(data)
      setMinAll(data.range.min)
      setMaxAll(data.range.max)

      setCountAll(data.summary)
    }
    const failCallback = () => {
      console.log('fail')
    }
    const debug = false

    // donot do list all again
    // requestToServer(
    //   url,
    //   method,
    //   body,
    //   verify,
    //   successCallback,
    //   failCallback,
    //   debug
    // )

    // y-m range
    const startDate = `${rangeQuery.from.year}-${
      rangeQuery.from.month < 10
        ? '0' + rangeQuery.from.month
        : rangeQuery.from.month
    }-01`

    const endDate = `${rangeQuery.to.year}-${
      rangeQuery.to.month < 10 ? '0' + rangeQuery.to.month : rangeQuery.to.month
    }-01`

    // set oneYear title
    setOneYearTitle(
      `${rangeQuery.from.year}.${rangeQuery.from.month}-${rangeQuery.to.year}.${rangeQuery.to.month}`
    )

    const queryOneYear = `region=${region}&postcode=${postcode}&year=1&type=${type}&street=${street}&coordinate=${coordinate}&startDate=${startDate}&endDate=${endDate}`
    const urlOneYear = `${hostUrl}/realestates/${path}/?${queryOneYear}`

    const successCallbackOneYear = (data) => {
      setDataOneYear(data)
      setMinOneYear(data.range.min)
      setMaxOneYear(data.range.max)
      // let tag = btype
      // if (btype === 0) tag = 'all'
      // const countAllNow = data.summary['count_type_' + tag]
      // setCountOneYear(countAllNow)
      setCountOneYear(data.summary)
      setTimeout(() => setLoading(false), 1200)
    }

    requestToServer(
      urlOneYear,
      method,
      body,
      verify,
      successCallbackOneYear,
      failCallback,
      debug
    )
  }, [rangeQuery])

  // did mount to request data from server
  // useEffect(() => {
  //   //open spinner
  //   setLoading(true)
  //   //console.log(townshipOrStation)

  //   //default
  //   let region = '信義區'
  //   let postcode = '110'

  //   if (townshipOrStation) {
  //     region = townshipOrStation.name
  //     postcode = townshipOrStation.postcode
  //   }

  //   const type = searchType ? searchType : 1
  //   const coordinate = townshipOrStation
  //     ? townshipOrStation.lat + ',' + townshipOrStation.lng
  //     : ''
  //   const path = type === 1 || type === 9 ? 'summary' : 'csummary'

  //   const query = `region=${region}&postcode=${postcode}&year=0&type=${type}&street=${street}&coordinate=${coordinate}`

  //   const url = `${hostUrl}/realestates/${path}/?${query}`
  //   const method = 'GET'
  //   const body = ''
  //   const verify = () => true
  //   const successCallback = (data) => {
  //     setDataAll(data)
  //     setMinAll(data.range.min)
  //     setMaxAll(data.range.max)

  //     setCountAll(data.summary)
  //   }
  //   const failCallback = () => {
  //     console.log('fail')
  //   }
  //   const debug = false

  //   requestToServer(
  //     url,
  //     method,
  //     body,
  //     verify,
  //     successCallback,
  //     failCallback,
  //     debug
  //   )

  //   const queryOneYear = `region=${region}&postcode=${postcode}&year=1&type=${type}&street=${street}&coordinate=${coordinate}`
  //   const urlOneYear = `${hostUrl}/realestates/${path}/?${queryOneYear}`

  //   const successCallbackOneYear = (data) => {
  //     setDataOneYear(data)
  //     setMinOneYear(data.range.min)
  //     setMaxOneYear(data.range.max)
  //     // let tag = btype
  //     // if (btype === 0) tag = 'all'
  //     // const countAllNow = data.summary['count_type_' + tag]
  //     // setCountOneYear(countAllNow)
  //     setCountOneYear(data.summary)
  //     setTimeout(() => setLoading(false), 1200)
  //   }

  //   requestToServer(
  //     urlOneYear,
  //     method,
  //     body,
  //     verify,
  //     successCallbackOneYear,
  //     failCallback,
  //     debug
  //   )
  // }, [])

  const scrollToTable = () => {
    scroller.scrollTo('myScrollToElement', {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: -80, // Scrolls to element + 50 pixels down the page
    })
  }

  const spinner = (
    <div className="row h-100 justify-content-center align-items-center">
      <div className="sweet-loading">
        <ScaleLoader
          css={override}
          size={60}
          color={'#0070bd'}
          loading={loading}
        />
      </div>
      <h4
        className="p-3"
        style={{
          color: '#0070bd',
        }}
      >
        資料查詢中
      </h4>
    </div>
  )

  const dataTable = (data, keytype) => {
    const validCount = []
    const count = []
    const avg = []
    const title = [
      '全部',
      '公寓',
      '公寓(不含一樓)',
      '套房',
      '透天',
      '大樓,一年以下',
      '大樓,一至五年',
      '大樓,五至十年',
      '大樓,十至二十年',
      '大樓,二十至三十年',
      '大樓,三十年以上',
      '店面',
      '工廠/廠辨',
    ]

    if (!data) return ''

    const summary = {
      ...data.summary,
    }

    for (let i = 0; i < 13; i++) {
      let tag = i

      // we donot need 0 (default)
      if (i === 0) tag = 'all'

      //console.log(summary['count_type_' + i])
      count.push(summary['count_type_' + tag])
      avg.push(summary['valid_avg_type_' + tag])
      validCount.push(summary['valid_count_type_' + tag])
    }

    // if (keytype === 'all') setCountAll(count)
    // else setCountOneYear(count)

    return (
      <Table size="sm">
        <thead>
          <tr>
            <th>類型</th>
            <th className="text-sm-right">均價 (萬元/坪)</th>
            <th className="text-sm-right">有效/總數</th>
          </tr>
        </thead>
        <tbody>
          {title.map((v, i, array) => {
            let text = (
              <div
                style={{
                  fontSize: 20,
                }}
              >
                {v}
              </div>
            )
            if (v.split(',')[1]) {
              text = (
                <div
                  style={{
                    fontSize: 20,
                  }}
                >
                  {v.split(',')[0]}{' '}
                  <small className="text-muted">{v.split(',')[1]}</small>
                </div>
              )
            }

            const backgroundColor =
              btype === i && keytypeGlobal === keytype
                ? 'rgb(240, 240, 240)'
                : '#ffffff'

            return (
              <tr
                key={i}
                onClick={() => {
                  //console.log(i)
                  setBtype(i)
                  scrollToTable()
                }}
                className="summary"
                style={{
                  cursor: 'pointer',
                  backgroundColor,
                }}
              >
                <td>{text}</td>
                <td className="h4 text-sm-right">
                  {(avg[i] / 10000).toFixed(2)}
                </td>
                <td>
                  <div className="text-muted text-sm-right">
                    {validCount[i] + '/' + count[i]}
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }

  const renderMap = () => {
    const type = searchType ? searchType : 1
    const radius = (+type - 1) * 100
    let zoom = 18
    if (type === 2) zoom = 16
    if (type === 3) zoom = 16
    if (type === 5) zoom = 15

    return type === 1 || type === 9 ? (
      <MapListRegion
        lat={townshipOrStation ? townshipOrStation.lat : undefined}
        lng={townshipOrStation ? townshipOrStation.lng : undefined}
      />
    ) : (
      <MapListCicle
        lat={townshipOrStation ? townshipOrStation.lat : undefined}
        lng={townshipOrStation ? townshipOrStation.lng : undefined}
        radius={radius}
        zoom={zoom}
      />
    )
  }

  return (
    <>
      <h3
        className="pt-2 pm-2"
        style={{
          color: 'rgba(190,188,188,1)',
        }}
      >
        區域行情
      </h3>

      <MonthRange setRangeQuery={setRangeQuery} />

      {loading ? (
        spinner
      ) : (
        <Row>
          <Col xs={12} sm={6}>
            <Tabs
              id="controlled-tab-example"
              activeKey={keytypeGlobal}
              onSelect={(k) => {
                setKeytypeGlobal(k)
                setBtype(0)
              }}
            >
              <Tab eventKey="all" title="全部">
                {dataTable(dataAll, 'all')}
              </Tab>
              <Tab eventKey="oneyear" title={oneYearTitle}>
                {dataTable(dataOneYear, 'oneyear')}
              </Tab>
            </Tabs>
          </Col>
          <Col xs={12} sm={6}>
            {renderMap()}
          </Col>
        </Row>
      )}
      <Row>
        <div className="m-2">
          <ul>
            <li>
              <p>
                資料來源:
                內政部不動產成交案件實際資訊資料供應系統。本資料僅供參考，實際資料仍以內政部不動產交易實價查詢服務網為主。
              </p>
            </li>
            <li>
              <p>
                均價有效係指該筆實價登錄資料位於一個標準差之內，且含有車位之資料需詳列車位坪數與價格，單價為拆分車位後的價格列入計算。
              </p>
            </li>
            <li>
              <p>
                單價於標準差之外，親屬關係人交易，或是車位坪數、總價無詳列者不列入計算。
              </p>
            </li>
            <li>
              <p>
                車位的坪數和價格拆分會影響建物每坪成交價的計算。例如：權狀登記
                30 坪，成交價 1200 萬，含車位 10 坪，車位價格 200
                萬，如不將車位拆分，每坪 1200 萬 / 30坪 = 40
                萬/坪。如將車位拆分，每坪 (1200 萬 - 200 萬) / (30 坪 - 10 坪) =
                50
                萬/坪。計算行情時，需將車位的坪數和價格從總坪數和總價中拆分出來，才能計算正確。
              </p>
            </li>
          </ul>
        </div>
      </Row>
      <Element name="myScrollToElement"></Element>
    </>
  )
}
