import React, { useState } from 'react'
import { Form, Col, Row, Button, Container } from 'react-bootstrap'
import { BsSearch } from 'react-icons/bs'
import TooltipIconButton from './TooltipIconButton'
import { useHistory } from 'react-router-dom'

export default function TypeInputRadioBox(props) {
  let history = useHistory()
  const {
    area,
    street,
    setStreet,
    searchType,
    setSearchType,
    townshipOrStation,
    countryOrLine,
    setSearchIsValid,
    getLatLngFromStreet,
  } = props

  const checkFormValid = () => {
    if (area === '縣市區域' && !countryOrLine) {
      alert('請先選擇縣市')
      return
    }

    if (area === '縣市區域' && countryOrLine && townshipOrStation == null) {
      alert('請先選擇鄉鎮市區')
      return
    }

    if (area !== '縣市區域' && !countryOrLine) {
      alert('請先選擇路線')
      return
    }

    if (area !== '縣市區域' && countryOrLine && townshipOrStation == null) {
      alert('請先選擇站點')
      return
    }

    if (searchType === 0) {
      alert('請先選擇搜尋方式')
      return
    }

    if (searchType === 9 && !street) {
      alert('請先輸入路段資訊')
      return
    }

    if (searchType && area === '縣市區域') {
      // getLatLng from getcoding
      getLatLngFromStreet(() => history.push('/rlist'))
      return
    }

    //setSearchIsValid(true)
    history.push('/rlist')
  }

  return (
    <>
      <hr />
      <Form>
        <Form.Row>
          <Col sm={6} className="pb-2 pr-2">
            {area !== '縣市區域' ? (
              ''
            ) : (
              <Form.Group controlId="inputStreet">
                <Form.Label
                  style={{
                    fontSize: 20,
                    display: 'block',
                    color: 'rgba(190,188,188,1)',
                  }}
                >
                  路段
                  <TooltipIconButton
                    name="說明"
                    info="輸入路段或街道名稱，不需要輸入完整門牌號碼或樓層。「路街段」請使用中文數字，例如「青年二路」或「國際一街」，「鄰巷弄號」則為阿拉伯數字，例如「3鄰105巷20弄」"
                  />
                </Form.Label>

                <Form.Control
                  type="text"
                  placeholder="請輸入路段，例如忠孝東路五段"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </Form.Group>
            )}
          </Col>
          <Col sm={area !== '縣市區域' ? 12 : 6} className="pb-2 pr-2">
            <fieldset>
              <Form.Group>
                <Form.Label
                  style={{
                    fontSize: 20,
                    display: 'block',
                    color: 'rgba(190,188,188,1)',
                  }}
                >
                  搜尋方式
                  <TooltipIconButton
                    name="說明"
                    info="選擇「全行政區域」時，將不會套用輸入的街道路段"
                  />
                </Form.Label>
                {area !== '縣市區域' ? (
                  ''
                ) : (
                  <>
                    <Form.Check
                      inline
                      name="typeRadios"
                      id="typeRadios9"
                      label="路段"
                      type="radio"
                      value={9}
                      checked={searchType === 9}
                      onChange={(e) => setSearchType(+e.target.value)}
                    />
                    <Form.Check
                      inline
                      name="typeRadios"
                      id="typeRadios1"
                      label="全行政區"
                      type="radio"
                      value={1}
                      checked={searchType === 1}
                      onChange={(e) => setSearchType(+e.target.value)}
                    />
                  </>
                )}
                <Form.Check
                  inline
                  name="typeRadios"
                  id="typeRadios2"
                  label="座標半徑 250m"
                  type="radio"
                  value={2}
                  checked={searchType === 2}
                  onChange={(e) => setSearchType(+e.target.value)}
                />
                <Form.Check
                  inline
                  name="typeRadios"
                  id="typeRadios3"
                  label="座標半徑 500m"
                  type="radio"
                  value={3}
                  checked={searchType === 3}
                  onChange={(e) => setSearchType(+e.target.value)}
                />
                <Form.Check
                  inline
                  name="typeRadios"
                  id="typeRadios4"
                  label="座標半徑 1km"
                  type="radio"
                  value={5}
                  checked={searchType === 5}
                  onChange={(e) => setSearchType(+e.target.value)}
                />
              </Form.Group>
            </fieldset>
          </Col>
        </Form.Row>
        <Form.Group as={Row} controlId="btnSubmit">
          <Col xs="auto">
            <Button
              type="button"
              variant="danger"
              style={{ minWidth: 150 }}
              className="mb-2"
              onClick={checkFormValid}
            >
              <BsSearch /> 搜尋
            </Button>
          </Col>
        </Form.Group>
      </Form>
    </>
  )
}
