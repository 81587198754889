import React from 'react'
import { Alert, Button } from 'react-bootstrap'
import { BsX } from 'react-icons/bs'
import { FaArrowCircleLeft } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom'

import StickyBox from 'react-sticky-box'

function FiexedTopPanel(props) {
  let location = useLocation()
  //console.log(location.pathname)
  const {
    countryOrLine,
    townshipOrStation,
    area,
    searchType,
    streetIsNeed,
  } = props
  const searchTypeTextArray = [
    '',
    '全區',
    '座標半徑 250m',
    '座標半徑 500m',
    '',
    '座標半徑 1km',
  ]
  return (
    <>
      {/* <div className="fixed-top" style={{ top: 75 }}> */}

      <Alert
        className="sticky-top sticky-offset"
        variant="secondary"
        style={{
          backgroundColor: '#f0f0f0',
          opacity: 1,
        }}
      >
        <div className="container">
          <h5>
            {location.pathname !== '/' ? (
              <>
                <Link to="/">
                  <FaArrowCircleLeft
                    size={30}
                    style={{ verticalAlign: 'middle' }}
                  />
                </Link>{' '}
              </>
            ) : (
              ''
            )}
            行情條件{' '}
            {countryOrLine && (
              <Button variant="outline-secondary" size="sm" className="m-1">
                {area} {countryOrLine}
              </Button>
            )}{' '}
            {townshipOrStation && (
              <Button variant="outline-secondary" size="sm" className="m-1">
                {townshipOrStation && townshipOrStation.name}
              </Button>
            )}{' '}
            {streetIsNeed && (
              <Button variant="outline-secondary" size="sm" className="m-1">
                {streetIsNeed}
              </Button>
            )}{' '}
            {searchType > 0 && (
              <Button variant="outline-secondary" size="sm" className="m-1">
                {searchType === 9 ? '路段' : searchTypeTextArray[searchType]}
              </Button>
            )}
          </h5>
        </div>
      </Alert>
    </>
  )
}

export default FiexedTopPanel
