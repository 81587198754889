import React from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react'

import { appConfig } from '../../config'

// const containerStyle = {
//   position: 'relative',
//   width: '100%',
//   height: '100%',
// }

class MapContainer extends React.Component {
  //if (!props.loaded) return <div>Loading...</div>
  constructor() {
    super()
    this.state = {
      currentLocation: {
        lat: 37.774929,
        lng: -122.419416,
      },
    }
  }

  componentDidMount() {
    if (this.props.centerAroundCurrentLocation) {
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((pos) => {
          const coords = pos.coords
          this.setState({
            currentLocation: {
              lat: coords.latitude,
              lng: coords.longitude,
            },
          })
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.google !== this.props.google) {
    //   this.loadMap()
    // }
    // if (prevState.currentLocation !== this.state.currentLocation) {
    //   this.recenterMap()
    // }
  }

  recenterMap() {
    const map = this.map
    const curr = this.state.currentLocation

    const google = this.props.google
    const maps = google.maps

    if (map) {
      let center = new maps.LatLng(curr.lat, curr.lng)
      map.panTo(center)
    }
  }
  // ...

  render() {
    return (
      <>
        <Map
          centerAroundCurrentLocation
          className="map"
          google={this.props.google}
          containerStyle={{
            width: '100%',
            height: '450px',
            position: 'relative',
          }}
          mapTypeControl={false}
          scaleControl={false}
          streetViewControl={false}
          fullscreenControl={false}
          style={{ height: '100%', position: 'relative', width: '100%' }}
          zoom={17}
          initialCenter={{
            lat: 25.0329694,
            lng: 121.5654177,
          }}
        >
          <Marker
            name={'Kenyatta International Convention Centre'}
            position={{ lat: this.props.lat, lng: this.props.lng }}
          />
        </Map>
      </>
    )
  }
}

// MapContainer.propTypes = {
//   google: React.PropTypes.object,
//   zoom: React.PropTypes.number,
//   initialCenter: React.PropTypes.object,
//   centerAroundCurrentLocation: React.PropTypes.bool,
// }

MapContainer.defaultProps = {
  zoom: 17,
  // San Francisco, by default
  initialCenter: {
    lat: 37.774929,
    lng: -122.419416,
  },
  centerAroundCurrentLocation: false,
}

export default GoogleApiWrapper({
  apiKey: appConfig.googleMapApiKey,
  language: 'zh-TW',
})(MapContainer)
