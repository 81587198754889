import React, { useState, useEffect } from 'react'
import {
  Navbar,
  Nav,
  Form,
  FormControl,
  Button,
  NavDropdown,
} from 'react-bootstrap'
import { Link, NavLink, withRouter } from 'react-router-dom'

function MyNavbar(props) {
  const { auth, name } = props

  const loginButton = (
    <>
      <Button
        variant="outline"
        onClick={() => {
          props.history.push('/memberlogin')
        }}
      >
        登入
      </Button>
    </>
  )
  const logoutButton = (
    <>
      <span style={{ color: '#ffffff' }}>{name}, 你好</span>
      <Button
        variant="outline-light"
        onClick={() => {
          props.history.push('/memberlogin')
        }}
      >
        登出
      </Button>
    </>
  )

  const displayButton = auth ? logoutButton : loginButton

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="light"
        bg="custom"
        fixed="top"
      >
        <Navbar.Brand href="https://house123.com.tw/">
          <img src="logo2x.png" style={{ width: 146 }} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <NavDropdown title="好事專欄" id="collasible-nav-dropdown">
              <NavDropdown.Item href="https://house123.com.tw/好室專欄/1安居自住">
                安居自住
              </NavDropdown.Item>
              <NavDropdown.Item href="https://house123.com.tw/好室專欄/2幸福收租">
                幸福收租
              </NavDropdown.Item>
              <NavDropdown.Item href="https://house123.com.tw/好室專欄/3開源節流">
                開源節流
              </NavDropdown.Item>
              <NavDropdown.Item href="https://house123.com.tw/好室專欄/4美好生活">
                美好生活
              </NavDropdown.Item>
              <NavDropdown.Item href="https://house123.com.tw/好室專欄/5微課堂">
                微課堂
              </NavDropdown.Item>
              <NavDropdown.Item href="https://house123.com.tw/好室專欄/學員購屋案例">
                學員購屋案例
              </NavDropdown.Item>
              <NavDropdown.Item href="https://house123.com.tw/好室專欄/學員心得分享">
                學員心得分享
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="課程講座" id="collasible-nav-dropdown">
              <NavDropdown.Item href="https://house123.com.tw/課程講座/包租隔套專班">
                包租隔套專班
              </NavDropdown.Item>
              <NavDropdown.Item href="https://house123.com.tw/課程講座/購屋實戰班">
                購屋實戰班
              </NavDropdown.Item>
              <NavDropdown.Item href="https://house123.com.tw/課程講座/開源節流">
                開源節流
              </NavDropdown.Item>
              <NavDropdown.Item href="https://house123.com.tw/%E8%AA%B2%E7%A8%8B%E8%AC%9B%E5%BA%A7/%E8%AC%9B%E5%BA%A7%E6%B4%BB%E5%8B%95">
                講座活動
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="https://house123.com.tw/購屋工具">
              購屋工具
            </Nav.Link>
            <Nav.Link href="https://house123.com.tw/預約諮詢">
              預約諮詢
            </Nav.Link>
            <NavDropdown title="房產知識+" id="collasible-nav-dropdown">
              <NavDropdown.Item href="https://house123.com.tw/%E6%88%BF%E7%94%A2%E7%9F%A5%E8%AD%98/%E8%B2%B7%E5%B1%8B%E6%95%99%E6%88%B0%E5%AE%88%E5%89%87">
                買屋教戰守則
              </NavDropdown.Item>
              <NavDropdown.Item href="https://house123.com.tw/房產知識/租賃新制問答集/房東房客常見問答">
                租賃新制問答集-房東房客
              </NavDropdown.Item>
              <NavDropdown.Item href="https://house123.com.tw/房產知識/租賃新制問答集/產業常見問答">
                租賃新制問答集-產業
              </NavDropdown.Item>
              <NavDropdown.Item href="https://house123.com.tw/房產知識/房地合一問答集">
                房地合一問答集
              </NavDropdown.Item>
              <NavDropdown.Item href="https://house123.com.tw/房產知識/實價登錄問答集">
                實價登錄問答集
              </NavDropdown.Item>
              <NavDropdown.Item href="https://house123.com.tw/房產知識/常見名詞">
                常見名詞
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="團購建案" id="collasible-nav-dropdown">
              <NavDropdown.Item href="https://docs.google.com/forms/d/e/1FAIpQLSeFKT6Phy1re5JfwNsHz4Qwy-MiZnP4D3-DXHW1mM-T9qupTQ/viewform">
                自住
              </NavDropdown.Item>
              <NavDropdown.Item href="https://docs.google.com/forms/d/e/1FAIpQLSef0pN0dt9zJ4RKXg4cnGpzS2PEihgHVwlUxBryqj7g7qc62A/viewform">
                投資
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default withRouter(MyNavbar)
