import React, { useState, useRef } from 'react'
//import { OverlayTrigger, Popover, Button } from 'react-bootstrap'
import {
  BsCaretDownFill,
  BsCaretUpFill,
  BsArrowDown,
  BsArrowUp,
} from 'react-icons/bs'

import { FaAngleUp, FaAngleDown } from 'react-icons/fa'
import { IconContext } from 'react-icons'
//import infoIcon from '../icon-info.svg'

export default function OrderDirectionIcon(props) {
  const { orderdirection, show } = props
  // const [show, setShow] = useState(false)
  // const target = useRef(null)

  // dont show icon
  if (!show) return <></>

  return (
    <>
      <IconContext.Provider
        value={{ color: '#0070bd', size: 18, verticalAlign: 'middle' }}
      >
        {orderdirection === 'desc' || orderdirection === '' ? (
          <FaAngleDown />
        ) : (
          <FaAngleUp />
        )}
      </IconContext.Provider>
    </>
  )
}
