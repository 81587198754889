import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'

import { Spinner, Container } from 'react-bootstrap'

import ListDataTableReal from '../components/ListDataTableReal'
import ListDataCollectionReal from '../components/ListDataCollectionReal'

import '../styles/list.scss'

// import { css } from '@emotion/core'
// import ScaleLoader from 'react-spinners/ScaleLoader'
//import { animateScroll as scroll } from 'react-scroll'
import ScrollToTop from 'react-scroll-to-top'
import { ReactComponent as MySVG } from '../up-chevron.svg'

// Can be a string as well. Need to ensure each key-value pair ends with ;
// const override = css`
//   display: block;
// `

function List(props) {
  // const [loading, setLoading] = useState(false)
  const [btype, setBtype] = useState(0)
  const [keytypeGlobal, setKeytypeGlobal] = useState('all')
  //console.log(props)
  const [minAll, setMinAll] = useState(0)
  const [maxAll, setMaxAll] = useState(0)
  const [minOneYear, setMinOneYear] = useState(0)
  const [maxOneYear, setMaxOneYear] = useState(0)
  const [countAll, setCountAll] = useState(0)
  const [countOneYear, setCountOneYear] = useState(0)

  // y-m range
  const [rangeQuery, setRangeQuery] = useState({
    from: {
      year: new Date().getFullYear() - 1,
      month: new Date().getMonth() + 1,
    },
    to: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    },
  })

  // useEffect(() => {
  //   setLoading(true)
  // }, [])

  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 3000)
  // }, [loading])

  // const spinner = (
  //   <>
  //     <div className="container h-100">
  //       <div
  //         className="row h-100 justify-content-center align-items-center"
  //         style={{ minHeight: '75vh', height: 500 }}
  //       >
  //         <div className="sweet-loading">
  //           <ScaleLoader
  //             css={override}
  //             size={60}
  //             color={'#0070bd'}
  //             loading={loading}
  //           />
  //         </div>
  //         <h4 className="p-3" style={{ color: '#0070bd' }}>
  //           資料查詢中
  //         </h4>
  //       </div>
  //     </div>
  //   </>
  // )

  return (
    <>
      <ScrollToTop smooth component={<MySVG />} />
      <ListDataCollectionReal
        {...props}
        btype={btype}
        setBtype={setBtype}
        keytypeGlobal={keytypeGlobal}
        setKeytypeGlobal={setKeytypeGlobal}
        setMinAll={setMinAll}
        setMaxAll={setMaxAll}
        setMinOneYear={setMinOneYear}
        setMaxOneYear={setMaxOneYear}
        setCountAll={setCountAll}
        setCountOneYear={setCountOneYear}
        rangeQuery={rangeQuery}
        setRangeQuery={setRangeQuery}
      />
      <ListDataTableReal
        {...props}
        btype={btype}
        keytypeGlobal={keytypeGlobal}
        minAll={minAll}
        maxAll={maxAll}
        maxOneYear={maxOneYear}
        minOneYear={minOneYear}
        countAll={countAll}
        countOneYear={countOneYear}
        rangeQuery={rangeQuery}
      />
    </>
  )
}

export default withRouter(List)
