import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import MyBanner from '../components/MyBanner'

import MapContainer from '../components/gmap/MapContainer'
import SearchTab from '../components/SearchTab'
import TypeInputRadioBox from '../components/TypeInputRadioBox'
import NewsFlash from '../components/NewsFlash'

function Home(props) {
  //console.log(props)
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <NewsFlash />
          <SearchTab {...props} />
          <TypeInputRadioBox {...props} />
        </div>
        {/* <div className="col-sm-4">
          <MapContainer />
          <div className="alert alert-info" role="alert">
            地圖上的紅色標記可以任意拖曳，拖曳後將變為範圍搜尋 (預設為 500
            公尺)。
          </div>
        </div> */}
      </div>
    </>
  )
}

export default withRouter(Home)
