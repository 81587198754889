import React, { Component } from 'react'
//import { Map, GoogleApiWrapper } from 'google-maps-react'
import {
  Map,
  //InfoWindow,
  Circle,
  Marker,
  GoogleApiWrapper,
} from 'google-maps-react'

import { appConfig } from '../../config'

const mapStyles = {
  width: '100%',
  height: '550px',
}

export class MapListCicle extends Component {
  static defaultProps = {
    lat: 25.0259029,
    lng: 121.5703875,
    radius: 1000, //meters
    zoom: 15,
  }

  render() {
    return (
      <Map
        google={this.props.google}
        containerStyle={{
          width: '100%',
          height: '550px',
          position: 'relative',
        }}
        zoom={this.props.zoom}
        mapTypeControl={false}
        scaleControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        style={mapStyles}
        initialCenter={{
          lat: this.props.lat,
          lng: this.props.lng,
        }}
      >
        <Circle
          radius={this.props.radius}
          center={{
            lat: this.props.lat,
            lng: this.props.lng,
          }}
          strokeColor="transparent"
          strokeOpacity={0}
          strokeWeight={5}
          fillColor="#FF0000"
          fillOpacity={0.2}
        />
        <Marker
          name={'物件位置'}
          position={{ lat: this.props.lat, lng: this.props.lng }}
        />
      </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: appConfig.googleMapApiKey,
  language: 'zh-TW',
})(MapListCicle)
