// tpemrt-future-v1: #c48c31
// v2, v3: #a1d884
// v4, v5, v6, v7: #FFDB00
// v8: #25aae1
// v9: #79bce8
// v10: #c3b091
// v11, v12: #e5554f

export const lines = [
  //v1
  { name: '淡水信義線延伸', variant: 'tpemrt-future-v1' },
  //v2
  {
    name: '萬大線第一期',
    variant: 'tpemrt-future-v2',
    info: '興建中，預計2025年底通車到莒光站',
  },
  //v3
  {
    name: '萬大線第二期',
    variant: 'tpemrt-future-v2',
    info: '尚未核定，僅供參考',
  },
  //v5
  {
    name: '環狀線－北環段',
    variant: 'tpemrt-future-v4',
    info: '預計2021年動工',
  },
  //v6
  {
    name: '環狀線－南環段',
    variant: 'tpemrt-future-v4',
    info: '預計2021年動工',
  },
  //v7
  {
    name: '環狀線－南北線',
    variant: 'tpemrt-future-v4',
    info: '尚未核定，僅供參考',
  },
  //v8
  {
    name: '民生汐止線第一期',
    variant: 'tpemrt-future-v8',
    info: '早在2014年11月曾經宣布動工，不過詳細地點都還未確認，僅供參考',
  },
  //v9
  {
    name: '三鶯線',
    variant: 'tpemrt-future-v9',
    info: '預計2023年底完工',
  },
  //v10
  {
    name: '安坑線',
    variant: 'tpemrt-future-v10',
    info: '預定完工年期為2021年12月',
  },
  //v11
  {
    name: '淡海輕軌藍海線第一期',
    variant: 'tpemrt-future-v11',
    info: '預計2020年初通車',
  },
  //v12
  {
    name: '淡海輕軌藍海線第二期',
    variant: 'tpemrt-future-v11',
    info: '預計2024年通車',
  },
]

export const stations = [
         [
           {
             name: '廣慈/奉天宮站',
             address: '',
             lat: 25.037921,
             lng: 121.582219,
           },
         ],
         [
           { name: '中正紀念堂 LG01', address: '', lat: null, lng: null },
           {
             name: '植物園 LG02',
             address: '',
             lat: 25.030609,
             lng: 121.509231,
           },
           { name: '廈安站 LG03', address: '', lat: 25.03015, lng: 121.504202 },
           {
             name: '加蚋站 LG04',
             address: '',
             lat: 25.021759,
             lng: 121.498695,
           },
           { name: '永和站 LG05', address: '', lat: 25.00852, lng: 121.505596 },
           { name: '中和站 LG06', address: '', lat: 25.001835, lng: 121.49662 },
           {
             name: '雙和醫院站 LG07',
             address: '',
             lat: 24.996206,
             lng: 121.490058,
           },
           {
             name: '中和高中站 LG08',
             address: '',
             lat: 24.995121,
             lng: 121.480961,
           },
           {
             name: '莒光站 LG08A',
             address: '',
             lat: 24.996156,
             lng: 121.470163,
           },
         ],
         [
           { name: '廷寮 LG09', address: '', lat: 24.989168, lng: 121.470207 },
           {
             name: '清水(中正國中) LG10',
             address: '',
             lat: 24.982814,
             lng: 121.455915,
           },
           {
             name: '土城 LG11',
             address: '新北市土城區金城路1段105號',
             lat: 24.972736,
             lng: 121.4436896,
           },
           { name: '埤塘 LG12', address: '', lat: 24.969229, lng: 121.440524 },
           {
             name: '城林橋 LG13',
             address: '',
             lat: 24.975321,
             lng: 121.438502,
           },
           { name: '溪洲 LG14', address: '', lat: 24.984648, lng: 121.426018 },
           { name: '彭厝 LG15', address: '', lat: 24.981585, lng: 121.419963 },
           { name: '備內 LG16', address: '', lat: 24.988279, lng: 121.415152 },
           { name: '樹林 LG17', address: '', lat: 24.996037, lng: 121.420836 },
           { name: '潭底 LG18', address: '', lat: 25.001037, lng: 121.425323 },
           {
             name: '監理所 LG19',
             address: '',
             lat: 25.007739,
             lng: 121.416867,
           },
           {
             name: '三多(三角埔) LG20',
             address: '',
             lat: 25.015328,
             lng: 121.410601,
           },
           { name: '迴龍 LG21', address: '', lat: 25.023369, lng: 121.414289 },
         ],
         [
           {
             name: '劍南路 Y32',
             address: '臺北市中山區北安路798號',
             lat: 25.084709,
             lng: 121.555612,
           },
           {
             name: '故宮(外雙溪) Y31',
             address: '',
             lat: 25.098402,
             lng: 121.547393,
           },
           {
             name: '林子口(雙溪公園) Y30',
             address: '',
             lat: 25.096455,
             lng: 121.532007,
           },
           {
             name: '士林 Y29',
             address: '臺北市士林區福德路1號',
             lat: 25.093535,
             lng: 121.52623,
           },
           {
             name: '士林區公所 Y28',
             address: '',
             lat: 25.093236,
             lng: 121.520073,
           },
           {
             name: '社正公園 Y27',
             address: '',
             lat: 25.087484,
             lng: 121.510717,
           },
           {
             name: '五華國小 Y26',
             address: '',
             lat: 25.088102,
             lng: 121.493036,
           },
           { name: '五華街 Y25', address: '', lat: 25.088904, lng: 121.485896 },
           {
             name: '徐匯中學 Y24',
             address: '新北市蘆洲區中山一路3號',
             lat: 25.08035,
             lng: 121.479925,
           },
           { name: '得仁里 Y23', address: '', lat: 25.076573, lng: 121.468525 },
           { name: '興珍村 Y22', address: '', lat: 25.074797, lng: 121.460206 },
           {
             name: '工商展覽中心 Y21',
             address: '',
             lat: 25.068054,
             lng: 121.451972,
           },
           {
             name: '新北產業園區 Y20',
             address: '新北市新莊區五工路37號',
             lat: 25.0617119,
             lng: 121.4588938,
           },
         ],
         [
           {
             name: '大坪林 Y7',
             address: '新北市新店區北新路3段190號',
             lat: 24.9831,
             lng: 121.5418,
           },
           {
             name: '遠東工業區 Y6',
             address: '',
             lat: 24.98345,
             lng: 121.54884,
           },
           {
             name: '溝子口(考試院) Y5',
             address: '',
             lat: 24.987307,
             lng: 121.548975,
           },
           { name: '馬明潭 Y4', address: '', lat: 24.988707, lng: 121.560275 },
           {
             name: '文山區公所 Y3',
             address: '',
             lat: 24.990557,
             lng: 121.570817,
           },
           { name: '政大 Y2', address: '', lat: 24.987726, lng: 121.575749 },
           {
             name: '動物園 Y1',
             address: '臺北市文山區新光路2段32號',
             lat: 24.9980685,
             lng: 121.57948,
           },
         ],
         [
           { name: '秀朗橋', address: '', lat: 24.99023, lng: 121.52573 },
           { name: '秀山', address: '', lat: 24.999099, lng: 121.526643 },
           { name: '福和', address: '', lat: 25.00613, lng: 121.526944 },
           {
             name: '公館',
             address: '臺北市中正區羅斯福路4段74號',
             lat: 25.0144012,
             lng: 121.5344194,
           },
           { name: '龍安', address: '', lat: 25.021538, lng: 121.543234 },
           {
             name: '六張犁',
             address: '臺北市大安區和平東路3段168號',
             lat: 25.0238135,
             lng: 121.5531506,
           },
           { name: '三張犁', address: '', lat: 25.033199, lng: 121.557385 },
           {
             name: '國父紀念館',
             address: '臺北市信義區忠孝東路4段400號',
             lat: 25.0411779,
             lng: 121.5580209,
           },
           {
             name: '中崙(監理處)',
             address: '',
             lat: 25.04836,
             lng: 121.558015,
           },
           { name: '西松', address: '', lat: 25.054219, lng: 121.563699 },
           { name: '舊宗', address: '', lat: 25.060245, lng: 121.579122 },
           { name: '時報廣場', address: '', lat: 25.068895, lng: 121.584064 },
           { name: '瑞光', address: '', lat: 25.075104, lng: 121.576864 },
           {
             name: '內湖科技園區',
             address: '',
             lat: 25.078855,
             lng: 121.56945,
           },
           {
             name: '下塔悠(濱江國中)',
             address: '',
             lat: 25.079953,
             lng: 121.558485,
           },
           {
             name: '劍南路',
             address: '臺北市中山區北安路798號',
             lat: 25.084709,
             lng: 121.555612,
           },
         ],
         [
           { name: '汐止 SB15', address: '', lat: 25.064883, lng: 121.659281 },
           { name: '汐科 SB14', address: '', lat: 25.063369, lng: 121.649752 },
           {
             name: '樟樹灣 SB13',
             address: '25.060984, 121.642224',
             lat: 25.0608975,
             lng: 121.6421275,
           },
           {
             name: '社后 SB12',
             address: '福德一路至工建路段',
             lat: 0,
             lng: 0,
           },
           {
             name: '下社后 SB11',
             address: '',
             lat: 25.066088,
             lng: 121.622173,
           },
           {
             name: '康樂(東湖支線) SB11A',
             address: '',
             lat: 25.070076,
             lng: 121.620776,
           },
           {
             name: '白馬山莊(東湖支線) SB11B',
             address: '',
             lat: 25.073649,
             lng: 121.621422,
           },
           {
             name: '內溝(東湖支線) SB11C',
             address: '',
             lat: 25.078395,
             lng: 121.624092,
           },
           {
             name: '東湖 SB10',
             address: '臺北市內湖區康寧路3段235號',
             lat: 25.0671258,
             lng: 121.6117183,
           },
           {
             name: '葫蘆洲 SB9',
             address: '',
             lat: 25.063112,
             lng: 121.603311,
           },
           {
             name: '湖興(國醫中心) SB8',
             address: '',
             lat: 25.069113,
             lng: 121.591841,
           },
           { name: '舊宗 SB7', address: '', lat: 25.060245, lng: 121.579122 },
         ],
         [
           {
             name: '頂埔 LB01',
             address: '新北市土城區中央路4段51之6號',
             lat: 24.9598747,
             lng: 121.4211303,
           },
           {
             name: '媽祖田站 LB02',
             address: '',
             lat: 24.952924,
             lng: 121.411871,
           },
           { name: '挖子 LB03', address: '', lat: 24.945489, lng: 121.404127 },
           { name: '橫溪 LB04', address: '', lat: 24.937553, lng: 121.391189 },
           { name: '龍埔 LB05', address: '', lat: 24.94021, lng: 121.384585 },
           {
             name: '三峽/國教院 LB05',
             address: '',
             lat: 24.938818,
             lng: 121.376757,
           },
           {
             name: '台北大學/恩主公醫院 LB07',
             address: '',
             lat: 24.93911,
             lng: 121.367147,
           },
           {
             name: '鶯歌車站 LB08',
             address: '',
             lat: 24.952542,
             lng: 121.357215,
           },
           {
             name: '陶瓷老街 LB09',
             address: '',
             lat: 24.952911,
             lng: 121.349029,
           },
           { name: '國華 LB10', address: '', lat: 24.955458, lng: 121.342604 },
           {
             name: '永吉公園 LB11',
             address: '',
             lat: 24.960867,
             lng: 121.334131,
           },
           {
             name: '鶯桃福德 LB12',
             address: '',
             lat: 24.967343,
             lng: 121.330191,
           },
         ],
         [
           { name: '雙城 K1', address: '', lat: 24.946647, lng: 121.489675 },
           {
             name: '玫瑰中國城 K2',
             address: '',
             lat: 24.951079,
             lng: 121.4941,
           },
           {
             name: '台北小城 K3',
             address: '',
             lat: 24.953851,
             lng: 121.499859,
           },
           {
             name: '耕莘安康院區 K4',
             address: '',
             lat: 24.954378,
             lng: 121.504731,
           },
           {
             name: '景文科大 K5',
             address: '',
             lat: 24.954752,
             lng: 121.509497,
           },
           { name: '安康 K6', address: '', lat: 24.964471, lng: 121.516968 },
           {
             name: '陽光運動公園 K7',
             address: '',
             lat: 24.973194,
             lng: 121.519682,
           },
           {
             name: '新和國小 K8',
             address: '',
             lat: 24.984418,
             lng: 121.517789,
           },
           { name: '十四張 K9', address: '', lat: 24.982928, lng: 121.533807 },
         ],
         [
           {
             name: '淡水漁人碼頭 V26',
             address: '',
             lat: 25.181644,
             lng: 121.418385,
           },
           { name: '沙崙 V27', address: '', lat: 25.187101, lng: 121.416883 },
           {
             name: '台北海洋學院 V28',
             address: '',
             lat: 25.191287,
             lng: 121.426705,
           },
         ],
         [
           { name: 'V21', address: '', lat: 25.168271, lng: 121.444581 },
           { name: 'V22R', address: '', lat: 25.172389, lng: 121.44046 },
           { name: 'V22L', address: '', lat: 25.170029, lng: 121.43995 },
           { name: 'V23', address: '', lat: 25.175275, lng: 121.430954 },
           { name: 'V24', address: '', lat: 25.175623, lng: 121.428355 },
           { name: 'V25', address: '', lat: 25.177687, lng: 121.423996 },
         ],
       ]
